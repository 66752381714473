import { ShapesElementProps } from "@/types/content-elements";

/**
 * Shape Divider for the section separator component.
 * @param {string} topColor hex code color which used for the top color
 * @param {string} bottomColor hex code color which used for the bottom color
 * @param {int} shapeHeight as px value how high the element should be
 * @param {boolean} shapeFlip should the shape be displayed inverted
 * @returns {JSX.Element}
 */
export default function ShapeDivider({
  topColor,
  bottomColor,
  shapeHeight,
  shapeFlip,
}: ShapesElementProps) {
  const divHeight = shapeHeight / 2;
  return (
    <>
      <section className="divider-section">
        <div className="divider-top"></div>
        <div className="divider-bottom"></div>
      </section>
      <style jsx>{`
        .divider-top {
          height: ${divHeight}px;
          background-color: ${shapeFlip ? bottomColor : topColor};
        }
        .divider-bottom {
          height: ${divHeight}px;
          background-color: ${shapeFlip ? topColor : bottomColor};
        }
      `}</style>
    </>
  );
}
